import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Text To CSV",
    link: "/text-to-csv-conversion/",
  },
]
const seeAlsoArray = [
  "/image-to-text",
  "/text-case-converter",
  "/text-to-array-conversion",
  "/text-emoticons-face-generator",
]

function TexttoCSV(props) {
  let [inputText, setinputText] = useState("")
  let [resultCsv, setresultCsv] = useState("")

  function inputTextC(e) {
    setinputText(e.target.value)
  }

  function convertTextToCsv() {
    setresultCsv(convertToCsv(inputText))
  }

  function convertToCsv(str) {
    var csv = str
      .split("\n\n")
      .map(function (row) {
        return "".concat(row)
      })
      .map(function (row) {
        return row
          .split(" ")
          .map(function (col) {
            return '"'.concat(col, '"')
          })
          .filter(function (col) {
            return col !== '""'
          })
          .join(",")
      })
      .join("\r\n")
    return csv
  }

  function convertToCsvNoQuote() {
    setresultCsv(convertNoQuote(inputText))
  }

  function convertNoQuote(str) {
    var csv = str
      .split("\n\n")
      .map(function (row) {
        return "".concat(row)
      })
      .map(function (row) {
        return row
          .split(" ")
          .map(function (col) {
            return " ".concat(col, " ")
          })
          .filter(function (col) {
            return col !== ",,"
          })
          .join(",")
      })
      .join("\r\n")
    return csv
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Text To CSV Converter"
        description="Text Tools Conversion, This tool transforms text column data to Comma Separated Values (CSV). You can set custom field separator character, custom field quotation character."
        keywords={[
          "Text To Csv Converter,Text To Csv translator,Text To Csv, Text To Csv translator,convert Text To Csv, Text To Csv convertion, Text To Csv, Text To CSV, Text To Csv converter and download.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Text To CSV Conversion</Title>
        <SubTitle>Text to CSV</SubTitle>

        <Span>Paste Your Text Here :</Span>
        <Textarea
          className="mb-2"
          placeholder="raw text"
          value={inputText}
          onChange={inputTextC}
        ></Textarea>

        <Button
          className="mb-2"
          type="button"
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          onClick={convertTextToCsv}
        >
          Convert To CSV
        </Button>

        <Button
          className="mb-2"
          type="button"
          borderColor="#1da1f2"
          color="white"
          backgroundColor="#1da1f2"
          hoverColor="#1da1f2"
          hoverBorderColor="#1da1f2"
          onClick={convertToCsvNoQuote}
        >
          Convert To CSV without quotes
        </Button>
        <Textarea
          className="mb-2"
          value={resultCsv}
          placeholder="csv result"
          readOnly
        ></Textarea>

        <br />
        <h3>Text to CSV</h3>
        <p>
          Free online transforms a plain text column to a CSV format. Just paste
          your text in the input above and click the button to get converted to
          CSV.
          <br />
          This tool transforms a text in column data to CSV format (Comma
          Separated Values).
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default TexttoCSV
